export const CREATE_COLLECTION_TX = `import BigDaddyContract from 0xBdn
import FungibleToken from 0xFungibleToken
import FlowToken from 0xFlow

transaction(name: String, price: UFix64, quantity: UInt32, royalties : UInt8, token: String, logoURL: String, nftImageURL: String) {
  let creatorReference: &BigDaddyContract.Collection{BigDaddyContract.MinterCollectionPublic}
  let sentVault: @FungibleToken.Vault
  let creator: Address
  
  prepare(acct: AuthAccount) {
    self.creatorReference = acct.borrow<&BigDaddyContract.Collection{BigDaddyContract.MinterCollectionPublic}>(from: BigDaddyContract.CollectionStoragePath) ?? panic("Could not borrow a reference to the minter's collection")
    
    self.creator = acct.address
    if (nftImageURL == "" && logoURL == ""){

      // Get a reference to the signer's stored vault
      let vaultRef = acct.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
          ?? panic("Could not borrow reference to the owner's Vault!")
      self.sentVault <- vaultRef.withdraw(amount: 0.0)
    }
    else
    {
      
        // Get a reference to the signer's stored vault
        let vaultRef = acct.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
            ?? panic("Could not borrow reference to the owner's Vault!")
        self.sentVault <- vaultRef.withdraw(amount: 10.0)
    }
    }
  
  execute {
    self.creatorReference.createBigDaddyNFTTemplate(name: name, 
                                                    price: price, 
                                                    quantity: quantity, 
                                                    creator: self.creator, 
                                                    royalties : royalties, 
                                                    token: token, 
                                                    logoURL: logoURL, 
                                                    nftImageURL:nftImageURL,
                                                    vault: <- self.sentVault)
  }
}`;