// BigDaddyContext.js

import { useState, createContext, useContext } from 'react';
import BigDaddyMinterTransactions from '../Flow/BigDaddyMinterTransactions';
import BigDaddyMinterScripts from '../Flow/BigDaddyMinterScripts';
import {useBDNHubContext} from "./bdnHubContext";

import {useBigDaddyToastAndErrorModal} from './BigDaddyToastAndErrorModalProvider';
const BigDaddyMinterContext = createContext();

export function useBigDaddyMinterContext() {
  return useContext(BigDaddyMinterContext);
}

export function BigDaddyMinterProvider({ children }) {
  const [creationComplete, setCreationComplete] = useState(false);

  const { user,
    setIsBigDaddyLoading} = useBDNHubContext();

  const bigDaddyScripts = new BigDaddyMinterScripts();
  const bigDaddyTransactions = new BigDaddyMinterTransactions();
  const {pushToast, showBigDaddyErrorModal} = useBigDaddyToastAndErrorModal();


  const getBigDaddyTemplates = async () => {
    setIsBigDaddyLoading(true);
    let collectionList = [];
    try {
      collectionList = await bigDaddyScripts.getTemplatesbyAuthor(user.addr);
    } catch (error) {
      showBigDaddyErrorModal(error.message);
    } finally {
      setIsBigDaddyLoading(false);
    }
    return collectionList;
  };

  const handleCreateBigDaddyNFTCollection = async (collectionName, price, quantity, royalties, token, logoURL, nftImageURL) => {
    setIsBigDaddyLoading(true);
    try {
      await bigDaddyTransactions.MintNewBigDaddyNFT(collectionName, price, quantity, royalties, token, logoURL, nftImageURL);
      await getBigDaddyTemplates();
      setIsBigDaddyLoading(true);
      pushToast();
      setCreationComplete(true);
    } catch (error) {
      showBigDaddyErrorModal(error.message);
    } finally {
      setIsBigDaddyLoading(false);
    }
  };


  return (
    <BigDaddyMinterContext.Provider value={{handleCreateBigDaddyNFTCollection,
                                            getBigDaddyTemplates,
                                            creationComplete,
                                            setCreationComplete}}>
      {children}
    </BigDaddyMinterContext.Provider>
  );
}
