import React from 'react';
import {
    CCard,
    CCardHeader,
    CCardBody,
    CButton,
    CRow,
    CCol,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilEnvelopeOpen, cilLink, cilMobile } from '@coreui/icons';

function BigDaddyContactPage() {
    const sendEmail = () => {
        window.location.href = "mailto:bigdaddy@bigdaddy-nft.com";
    }

    const goToMattermost = () => {
        window.open("https://mattermost.bigdaddy-nft.com/", "_blank");
    }

    const chat = () => {
        window.open("https://api.whatsapp.com/send?phone=00261382081002", "_blank");
    }
    return (
        <CCard className="m-5" style={{ background: "rgba(255, 255, 255, 0.6)" }}>
            <CCardHeader className="text-center" style={{ background: "rgba(255, 255, 255, 0.4)" }}>
                <p style={{
                    background: 'linear-gradient(to right, #0099cc, #008ca8, #00b580, #00ff80)',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    fontSize: "42px"
                }}>
                    Let's Get In Touch!
                </p>
            </CCardHeader>

            <CCardBody>
                <CRow>
                    <CCol md="4" className="text-center my-3">
                        <CIcon icon={cilEnvelopeOpen} size="4xl" style={{ color: '#0099cc' }} />
                        <h4>Email Us Directly</h4>
                        <p>For general queries and partnership opportunities.</p>
                        <button className="bigdaddy-button" onClick={sendEmail}>
                            Send Email
                        </button>
                    </CCol>

                    <CCol md="4" className="text-center my-3">
                        <CIcon icon={cilLink} size="4xl" style={{ color: '#00b580' }} />
                        <h4>Join Developer's Mattermost</h4>
                        <p>Communicate, collaborate, and stay updated with our development community.</p>
                        <button className="bigdaddy-button" onClick={goToMattermost}>
                            Go to Mattermost
                        </button>
                    </CCol>

                    <CCol md="4" className="text-center my-3">
                        <CIcon icon={cilMobile} size="4xl" style={{ color: '#00ff80' }} />
                        <h4>Chat on WhatsApp</h4>
                        <p>Directly connect with our team and get instant responses.</p>
                        <button className="bigdaddy-button" onClick={chat}>
                            Start Chatting
                        </button>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    );
}

export default BigDaddyContactPage;
