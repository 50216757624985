import React from 'react';
import {
    CCard,
    CCardHeader,
    CCardBody,
    CRow,
    CCol,
    CCardFooter,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilPuzzle, cilGroup, cilCode } from '@coreui/icons';

function BigDaddyDevExpPage() {
    return (
        <CCard className="m-5" style={{ background: "rgba(255, 255, 255, 0.6)" }}>
            <CCardHeader className="text-center" style={{ background: "rgba(255, 255, 255, 0.4)" }}>
                <p style={{
                    background: 'linear-gradient(to right, #0099cc, #008ca8, #00b580, #00ff80)',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    fontSize: "42px"
                }}>
                    Developer Experience with bdnHub
                </p>
            </CCardHeader>

            <CCardBody>
                <CRow>
                    <CCol md="4" className="text-center my-5">
                        <CIcon icon={cilPuzzle} size="4xl" style={{ color: '#0099cc' }} />
                        <h3 style={{ paddingTop: "10px", color: '#0099cc', fontWeight: 'bold' }}>bdnPlugin</h3>
                        <p style={{ paddingTop: "10px" }}>
                            Discover our open-source plugin, where everyone can make a difference. Dive into a world where web3 development thrives. Ensure website access exclusively for NFT owners.
                            <br />
                            <a href="https://github.com/BigDaddyNFT/BigDaddyPlugin/" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold', fontSize: '18px' }}>Learn more</a>
                        </p>
                    </CCol>

                    <CCol md="4" className="text-center my-5">
                        <CIcon icon={cilGroup} size="4xl" style={{ color: '#008ca8' }} />
                        <h3 style={{ paddingTop: "10px", color: '#008ca8', fontWeight: 'bold' }}>BigDaddyNFT Mattermost</h3>
                        <p style={{ paddingTop: "10px" }}>
                            Join our Mattermost community! Connect, communicate, and collaborate with developers passionate about our project.
                            <br />
                            <a href="https://mattermost.bigdaddy-nft.com/" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold', fontSize: '18px' }}>Join the conversation</a>
                        </p>
                    </CCol>

                    <CCol md="4" className="text-center my-5">
                        <CIcon icon={cilCode} size="4xl" style={{ color: '#00b580' }} />
                        <h3 style={{ paddingTop: "10px", color: '#00b580', fontWeight: 'bold' }}>bdnhub Developer Page</h3>
                        <p style={{ paddingTop: "10px" }}>
                            Unleash your creativity and profit from it! Register a website template and watch it thrive on the BigDaddyMarketplace.
                            <br />
                            <a href="https://bdnhub.com/dev" target="_blank" rel="noopener noreferrer" style={{ fontWeight: 'bold', fontSize: '18px' }}>Get started</a>
                        </p>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
    );
}

export default BigDaddyDevExpPage;
