// BigDaddyContext.js

import { useState, createContext, useContext } from 'react';
import BigDaddyDeveloperTransactions from '../Flow/BigDaddyDeveloperTransactions';
import BigDaddyDeveloperScripts from '../Flow/BigDaddyDeveloperScripts';
import {useBDNHubContext} from "./bdnHubContext";
import { useBigDaddyToastAndErrorModal } from './BigDaddyToastAndErrorModalProvider';

const BigDaddyDeveloperContext = createContext();

export function useBigDaddyDeveloperContext() {
  return useContext(BigDaddyDeveloperContext);
}

export function BigDaddyDeveloperProvider({ children }) {
  const [nftTemplate, setNFTTemplate] = useState(null);

  const { user,
    setIsBigDaddyLoading} = useBDNHubContext();

  const bigDaddyScripts = new BigDaddyDeveloperScripts();
  const bigDaddyTransactions = new BigDaddyDeveloperTransactions();
  const {pushToast, showBigDaddyErrorModal} = useBigDaddyToastAndErrorModal();


  const getBigDaddyDeveloperTemplate = async () => {
    setIsBigDaddyLoading(true);
    let template = null;
    try {
      template = await bigDaddyScripts.getTemplatebyAuthor(user.addr);
      setNFTTemplate(template);
    } catch (error) {
      showBigDaddyErrorModal(error.message);
    } finally {
      setIsBigDaddyLoading(false);
    }
    return template;
  };

  const handleCreateNewBigDaddyNFTTemplate = async (name, price, projectId, description, miniatureURL, picture1Url, picture2Url, picture3Url, videoUrl, creatorwebsiteUrl, demowebsiteUrl, token) => {
    setIsBigDaddyLoading(true);
    try {
      await bigDaddyTransactions.CreateNewBigDaddyNFTTemplate(name, price, projectId, description, miniatureURL, picture1Url, picture2Url, picture3Url, videoUrl, creatorwebsiteUrl, demowebsiteUrl, token)
      await getBigDaddyDeveloperTemplate();
      pushToast();
    } catch (error) {
      showBigDaddyErrorModal(error.message);
    } finally {
      setIsBigDaddyLoading(false);
    }
  };

  const handleUpdateBigDaddyNFTTemplate = async (id,
                                                 name,
                                                 price,
                                                 projectId,
                                                 description,
                                                 miniatureURL,
                                                 picture1Url,
                                                 picture2Url,
                                                 picture3Url,
                                                 videoUrl,
                                                 creatorwebsiteUrl,
                                                 demowebsiteUrl,
                                                 token) => {
    setIsBigDaddyLoading(true);
    try {
      await bigDaddyTransactions.UpdateBigDaddyNFTTemplate(id,
                                                          name,
                                                          price,
                                                          projectId,
                                                          description,
                                                          miniatureURL,
                                                          picture1Url,
                                                          picture2Url,
                                                          picture3Url,
                                                          videoUrl,
                                                          creatorwebsiteUrl,
                                                          demowebsiteUrl,
                                                          token);
      await getBigDaddyDeveloperTemplate();
      pushToast();
    } catch (error) {
      showBigDaddyErrorModal(error.message);
    } finally {
      setIsBigDaddyLoading(false);
    }
  };

  return (
    <BigDaddyDeveloperContext.Provider value={{ nftTemplate,
                                            getBigDaddyDeveloperTemplate,
                                            handleCreateNewBigDaddyNFTTemplate, 
                                            handleUpdateBigDaddyNFTTemplate}}>
      {children}
    </BigDaddyDeveloperContext.Provider>
  );
}
