import * as fcl from "@onflow/fcl"

// Importez vos transactions Cadence
import { CREATE_NEW_NFT_TEMPLATE_TX } from './transactions/CreateNewNFTTemplate'
import { UPDATE_NFT_TEMPLATE_TX } from "./transactions/UpdateNFTTemplate"

class BigDaddyDeveloperTransactions {

  async sendTransaction(cadenceCode, args ) {
    const response = await fcl.send([
      fcl.transaction(cadenceCode),
      args,
      fcl.limit(9999),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.payer(fcl.currentUser().authorization),
      fcl.authorizations([               // add this
        fcl.currentUser().authorization  // add this
      ]),
    ])

    const transactionId = await fcl.decode(response)

    await fcl.tx(transactionId).onceSealed()

    return transactionId
  }

  async CreateNewBigDaddyNFTTemplate(name, price, projectId, description, miniatureURL, picture1Url, picture2Url, picture3Url, videoUrl, creatorwebsiteUrl, demowebsiteUrl, token) {
    let args = fcl.args([
      fcl.arg(name, fcl.t.String),
      fcl.arg(price, fcl.t.UFix64),
      fcl.arg(projectId, fcl.t.String),
      fcl.arg(description, fcl.t.String),
      fcl.arg(miniatureURL, fcl.t.String),
      fcl.arg(picture1Url, fcl.t.String),
      fcl.arg(picture2Url, fcl.t.String),
      fcl.arg(picture3Url, fcl.t.String),
      fcl.arg(videoUrl, fcl.t.String),
      fcl.arg(creatorwebsiteUrl, fcl.t.String),
      fcl.arg(demowebsiteUrl, fcl.t.String),
      fcl.arg(token, fcl.t.String)
    ])
    return this.sendTransaction(CREATE_NEW_NFT_TEMPLATE_TX, args)
  }

  async UpdateBigDaddyNFTTemplate(templateId, name, price, projectId, description, miniatureURL, picture1Url, picture2Url, picture3Url, videoUrl, creatorwebsiteUrl, demowebsiteUrl, token) {
    let args = fcl.args([
      fcl.arg(templateId, fcl.t.UInt32),
      fcl.arg(name, fcl.t.String),
      fcl.arg(price, fcl.t.UFix64),
      fcl.arg(projectId, fcl.t.String),
      fcl.arg(description, fcl.t.String),
      fcl.arg(miniatureURL, fcl.t.String),
      fcl.arg(picture1Url, fcl.t.String),
      fcl.arg(picture2Url, fcl.t.String),
      fcl.arg(picture3Url, fcl.t.String),
      fcl.arg(videoUrl, fcl.t.String),
      fcl.arg(creatorwebsiteUrl, fcl.t.String),
      fcl.arg(demowebsiteUrl, fcl.t.String),
      fcl.arg(token, fcl.t.String)
    ])
    return this.sendTransaction(UPDATE_NFT_TEMPLATE_TX, args)
  }

}

export default BigDaddyDeveloperTransactions
