import React from 'react';
import {
    CCard,
    CRow,
    CCol,
    CCardBody,
    CTooltip,
    CCardHeader,
    CCardFooter, CContainer,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilCheck, cilX, cilInfo } from '@coreui/icons';
import BigDaddyHowItWorks from "./BigDaddyHowItWorksPage";

const features = [
    { name: "Decentralized Architecture", icons: [cilCheck, cilCheck, cilX] },
    { name: "No Financial Middlemen", icons: [cilCheck, cilCheck, cilX] },
    { name: "Commission", value: ['0%', '0%', '5-8%'] },
    { name: "Personal Website", icons: [cilCheck, cilCheck, cilX] },
    { name: "Possible ROI for Backers (NFT SecondHand Market)", icons: [cilCheck, cilCheck, cilX] },
    { name: "Crypto Payment", icons: [cilCheck, cilCheck, cilX] },
    { name: "Dedicated Smart Contract", icons: [ cilX, cilCheck, cilX] },
    { name: "Customized Website", icons: [ cilX, cilCheck, cilX] },
    { name: "Support", value: ['No', 'Email', 'Paid'] }
];

function BigDaddyWhyUsPage() {
    const cardstyle = {
        background: "black",
        borderRadius: "15px", // Bordures arrondies
        boxShadow: "0px 4px 6px #00000029",
        maxWidth: "520px", width: '100%'// Ombre
    }
    return (
        <CContainer fluid className="p-0">
            <div className="bdn-centered-title">
                <p style={{ color: 'white'}}> Why us?</p>
                <p style={{
                    background: 'linear-gradient(to right, #00c9ff, #00bcd4, #00e19d, #07934e)',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    fontWeight: 'bold',
                    fontSize: '2rem',
                    paddingBottom: '20px'}}>Discover how the disruptive approach of bdnHub gives him advantage on opponents (Patreon, Tipee ...)</p>
                <CContainer fluid >
                    <CRow className="d-flex justify-content-around">
                {["bdnHub Free", "bdnHub Pro", "Other Solutions"].map((title, idx) => (
                    <CCol md="3" className="text-center my-5">
                <CCard style={cardstyle} className="h-100 mb-3 text-center animated-card ">
                    <CCardHeader style={{ color: "white" }}>
                        {title}
                        {title === "bdnHub Pro" && (
                            <CTooltip content="For more details, contact us">
                                <CIcon icon={cilInfo} />
                            </CTooltip>
                        )}
                    </CCardHeader>
                    <CCardBody>
                        {features.map((feature) => (
                            <div className="mb-3 text-center " style={{ fontSize: "14px" }}>
                                {feature.name}: {
                                feature.icons ? (
                                    <CIcon
                                        icon={feature.icons[idx]}
                                        style={
                                            feature.icons[idx] === cilCheck
                                                ? { color: 'green' }
                                                : { color: 'red' }
                                        }
                                    />
                                ) : feature.value[idx]
                            }
                            </div>
                        ))}
                    </CCardBody>
                </CCard>
                    </CCol>
            ))}
                        </CRow>
                </CContainer>
        </div>
        </CContainer>
    );
}
export default BigDaddyWhyUsPage;