import React from "react";

function BigDaddyActivateAccountPage() {

  return (

    <>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
    <h1 style={{ margin: '50px', color: 'white' }}>Activate your Account First</h1>
   </div>
    </>
  );
}

export default BigDaddyActivateAccountPage;
