export const HAS_BIGDADDY_COLLECTIONS_SCRIPT = `import BigDaddyMarketplaceContract from 0xBdn
import BigDaddyContract from 0xBdn

pub fun main(addr: Address): Bool {
    
   if let myref = getAccount(addr).getCapability(BigDaddyMarketplaceContract.NFTMarketplaceCollectionPublicPath).borrow<&{BigDaddyMarketplaceContract.CollectionPublic}>()
   {
       if let myref2 = getAccount(addr).getCapability(BigDaddyContract.CollectionPublicPath).borrow<&{BigDaddyContract.CollectionPublic}>()
       {
        return true
       }
   }
   
   return false
        
}`;