import * as fcl from "@onflow/fcl"

// Importez vos scripts Cadence
import { HAS_BIGDADDY_COLLECTIONS_SCRIPT } from "./scripts/HasBigDaddyCollections"
import { GET_FLOW_BALANCE } from "./scripts/GetFLOWBalance"
import { GET_USDC_BALANCE } from "./scripts/GetUSDCBalance"

class BdnHubScripts {

    async executeScript(cadenceCode, args = []) {
        const response = await fcl.send([
            fcl.script(cadenceCode),
            args,
        ])

        return fcl.decode(response)
    }

    async hasBigDaddyCollections(addr) {
        let args = fcl.args([
            fcl.arg(addr, fcl.t.Address)
        ])
        return this.executeScript(HAS_BIGDADDY_COLLECTIONS_SCRIPT, args)
    }

    async getUSDCBalance(addr) {
        let args = fcl.args([
            fcl.arg(addr, fcl.t.Address)
        ])
        return this.executeScript(GET_USDC_BALANCE, args)
    }

    async getFLOWBalance(addr) {
        let args = fcl.args([
            fcl.arg(addr, fcl.t.Address)
        ])
        return this.executeScript(GET_FLOW_BALANCE, args)
    }
}
export default BdnHubScripts
