import React from "react";
import { CRow } from "@coreui/react";

function BigDaddyLoginPage() {

  return (
    <>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
    <CRow className="mt-5 mb-5" xs={{ gutterX: 5 }}>  {/* Increased margin-top (mt-5) */}
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
        Login First
      </div>
    </CRow>
  </div>
  </>
  );
}

export default BigDaddyLoginPage;