import React, { useEffect, useState } from "react";
import {
    CNavbar,
    CNavbarBrand,
    CContainer,
    CDropdownToggle,
    CDropdown,
    CDropdownMenu,
    CDropdownDivider,
    CNavLink,
    CNavbarNav,
    CNavbarToggler,
    CCollapse, CForm, CNavItem, CButton
} from "@coreui/react";
import { useLocation, useNavigate } from "react-router-dom";
import * as fcl from "@onflow/fcl";
import { useBDNHubContext } from "../../Providers/bdnHubContext";

function BigDaddyNavBar() {
    const {
        isLoggedIn,
        isCollectionEnabled,
        user,
        flowBalance,
        usdcBalance,
        handleActivateBigDaddyCollections,
        validateLoggedIn,
        getBalance,
        hasBigDaddyCollections,
        disconnect
    } = useBDNHubContext();

    const location = useLocation();
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        fcl.currentUser.subscribe((currentUser) => {
            if (currentUser.cid) {
                validateLoggedIn(currentUser);
            } else {
                disconnect();
            }
        });
    }, [validateLoggedIn, disconnect]);

    useEffect(() => {
        if (user) {
            getBalance();
            hasBigDaddyCollections();
        }
    }, [user, flowBalance, usdcBalance, isCollectionEnabled]);

    const handleLogOut = () => {
        fcl.unauthenticate();
    };
    const handleLogIn = (event) => {
        event.preventDefault();
        fcl.logIn().then();
    };

    const redirectTo = (path) => {
        navigate(path);
    };

    function isActive(path) {
        return location.pathname === path;
    }

    function activateBigDaddyCollections(event) {
        event.preventDefault();
        handleActivateBigDaddyCollections();
    }
    const navLinkStyle = {
        "--cui-nav-link-color": "#510e7c",
        "--cui-nav-link-hover-color": "#ab069d",
        "--cui-nav-link-font-size": "20pt",
        cursor: "pointer",
        paddingRight: "40px"
    };

    const navSubLinkStyle = {
        "--cui-nav-link-color": "#510e7c",
        "--cui-nav-link-hover-color": "#ab069d",
        "--cui-nav-link-font-size": "15pt",
        cursor: "pointer",
        paddingLeft: "5px",
        paddingTop: "5px"
    };

    const navAboutStyle={
        '--cui-navbar-color': '#510e7c',
        '--cui-navbar-hover-color': '#ab069d',
        '--cui-nav-link-font-size': '20pt'
    };

    const navAccountStyle={
        '--cui-nav-link-color': '#510e7c',
        '--cui-nav-link-hover-color': '#ab069d',
        '--cui-nav-link-font-size': '20pt',
        paddingTop: "5px"

    };

    return (
            <CNavbar expand="xxl" colorScheme="light" className="bg-light w-100">
                <CContainer fluid>
                <CNavbarBrand href="/">
                    <img
                        src={"/bdnhub-little.png"}
                        alt="bdnHub"
                        height="50"
                        className="d-inline-block align-center px-lg-3"

                    />
                </CNavbarBrand>
                <CNavbarToggler onClick={() => setVisible(!visible)} />
                <CCollapse className="navbar-collapse justify-content-lg-between" visible={visible}>
                        <CNavbarNav style={navAboutStyle}>
                            <CNavItem>
                            <CNavLink
                                onClick={() => redirectTo("/mkt")}
                                style={{
                                    ...navLinkStyle,
                                    textDecoration: isActive("/mkt")
                                        ? "underline"
                                        : "none"
                                }}
                            >
                                Marketplace
                            </CNavLink>
                            </CNavItem>
                            {isLoggedIn && isCollectionEnabled && (
                                <>

                                <CNavItem>
                                    <CNavLink
                                        onClick={() => redirectTo("/websites")}
                                        style={{
                                            ...navLinkStyle,
                                            textDecoration: isActive("/websites")
                                                ? "underline"
                                                : "none"
                                        }}
                                    >
                                        My Websites
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink
                                        onClick={() => redirectTo("/collections")}
                                        style={{
                                            ...navLinkStyle,
                                            textDecoration: isActive("/collections")
                                                ? "underline"
                                                : "none"
                                        }}
                                    >
                                        My NFT Collections
                                    </CNavLink>
                                </CNavItem>
                                </>
                            )}
                            <CNavItem>
                            <CDropdown variant="nav-item" popper={false} >
                                <CDropdownToggle color={"white"} >About</CDropdownToggle>
                                <CDropdownMenu>
                                    <CNavLink onClick={() =>redirectTo("/HiW")} style={navSubLinkStyle}>How it works?</CNavLink>
                                    <CDropdownDivider />
                                    <CNavLink onClick={() =>redirectTo("/WhyUs")} style={navSubLinkStyle}>Why us?</CNavLink>
                                    <CDropdownDivider />
                                    <CNavLink onClick={() =>redirectTo("/Start")} style={navSubLinkStyle}>Start</CNavLink>
                                    <CDropdownDivider />
                                    <CNavLink onClick={() =>redirectTo("/DevExp")} style={navSubLinkStyle}>Developer</CNavLink>
                                    <CDropdownDivider />
                                    <CNavLink onClick={() =>redirectTo("/Contact")} style={navSubLinkStyle}>Contact</CNavLink>
                                </CDropdownMenu>
                            </CDropdown>
                                </CNavItem>
                        </CNavbarNav>
                        <CForm className="d-flex justify-content-lg-end" >
                            {isLoggedIn ? (
                                !isCollectionEnabled ? (
                                    <CDropdown>
                                        <CDropdownToggle color={"white"} style={{ paddingRight: "0px" }} />
                                        <CNavLink style={navLinkStyle}>My Account</CNavLink>
                                        <CDropdownMenu>
                                            <button onClick={activateBigDaddyCollections} className="bigdaddy-button">
                                                Activate Account
                                            </button>
                                            <CDropdownDivider />
                                            <button onClick={handleLogOut} className="bigdaddy-button">
                                                Log Out
                                            </button>
                                        </CDropdownMenu>
                                    </CDropdown>
                                ) : (
                                    <CDropdown>
                                        <CNavLink style={navAccountStyle}>My Account</CNavLink>
                                        <CDropdownToggle color={"white"} style={{"--cui-btn-font-size":"20pt"}}/>
                                        <CDropdownMenu>
                                            <p style={{ paddingLeft: "5px", color: "#47013e" }}>Wallet address: {user.addr}</p>
                                            <p style={{ paddingLeft: "5px", paddingTop: "5px", color: "#47013e" }}>FLOW Balance: {parseFloat(flowBalance).toFixed(3)} FLOW</p>
                                            <p style={{ paddingLeft: "5px", paddingTop: "5px", color: "#47013e" }}>USDC Balance: {parseFloat(usdcBalance).toFixed(3)} USDC</p>
                                            <CDropdownDivider />
                                            <button onClick={handleLogOut} className="bigdaddy-button">
                                                Log Out
                                            </button>
                                        </CDropdownMenu>
                                    </CDropdown>
                                )
                            ) : (
                                <button className="bigdaddy-button" onClick={handleLogIn}>
                                    Log In
                                </button>
                            )}
                        </CForm>
                </CCollapse>
                </CContainer>
            </CNavbar>
    );
}

export default BigDaddyNavBar;
