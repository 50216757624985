import * as fcl from "@onflow/fcl"

// Importez vos scripts Cadence
import { GET_TEMPLATE_BY_AUTHOR_SCRIPT } from './scripts/GetMarketplaceTemplatebyAuthor'

class BigDaddyDeveloperScripts {

  async executeScript(cadenceCode, args = []) {
    const response = await fcl.send([
      fcl.script(cadenceCode),
      args,
    ])

    return fcl.decode(response)
  }

  async getTemplatebyAuthor(addr) {
    let args = fcl.args([
      fcl.arg(addr, fcl.t.Address)
    ])
    return this.executeScript(GET_TEMPLATE_BY_AUTHOR_SCRIPT, args)
  }

}

export default BigDaddyDeveloperScripts
