export const DEPLOY_NFT_TX = `import BigDaddyMarketplaceContract from 0xBdn
import FungibleToken from 0xFungibleToken
import FlowToken from 0xFlow

transaction(nftId: UInt64, websiteTitle: String, websiteDescription: String, siteId: String, premiumUrlPrefix: String, deploymentOption: String) {
  let deployerReference: &BigDaddyMarketplaceContract.Collection{BigDaddyMarketplaceContract.CollectionPublic}
  let sentVault: @FungibleToken.Vault
  let deployer: Address

  prepare(acct: AuthAccount) {
    self.deployerReference = acct.borrow<&BigDaddyMarketplaceContract.Collection{BigDaddyMarketplaceContract.CollectionPublic}>(from: BigDaddyMarketplaceContract.NFTMarketplaceCollectionStoragePath) 
        ?? panic("Cannot borrow")

    self.deployer = acct.address

    if (premiumUrlPrefix == "" && deploymentOption == "Free"){
      // Get a reference to the signer's stored vault
      let vaultRef = acct.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
          ?? panic("Could not borrow reference to the owner's Vault!")
      self.sentVault <- vaultRef.withdraw(amount: 0.0)
    }
    else if (deploymentOption == "GOLD"){
        let vaultRef = acct.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
            ?? panic("Could not borrow reference to the owner's Vault!")
        self.sentVault <- vaultRef.withdraw(amount: 100.0)
    }
    else if (deploymentOption == "SILVER"){
        let vaultRef = acct.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
            ?? panic("Could not borrow reference to the owner's Vault!")
        self.sentVault <- vaultRef.withdraw(amount: 50.0)
    }
    else {
        let vaultRef = acct.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
            ?? panic("Could not borrow reference to the owner's Vault!")
        self.sentVault <- vaultRef.withdraw(amount: 20.0)
    }
  }

  execute {
    self.deployerReference.deployBigDaddyMarketplaceNFT(deployer: self.deployer, nftId: nftId, websiteTitle: websiteTitle, websiteDescription: websiteDescription, siteId: siteId, urlPrefix: premiumUrlPrefix, deploymentOption: deploymentOption, vault: <- self.sentVault)
  }
}`;