import * as fcl from "@onflow/fcl"

// Importez vos transactions Cadence
import { CREATE_COLLECTION_TX } from './transactions/CreateCollection'

class BigDaddyMinterTransactions {

  async sendTransaction(cadenceCode, args ) {
    const response = await fcl.send([
      fcl.transaction(cadenceCode),
      args,
      fcl.limit(9999),
      fcl.proposer(fcl.currentUser().authorization),
      fcl.payer(fcl.currentUser().authorization),
      fcl.authorizations([               // add this
        fcl.currentUser().authorization  // add this
      ]),
    ])

    const transactionId = await fcl.decode(response)

    await fcl.tx(transactionId).onceSealed()

    return transactionId
  }

  async MintNewBigDaddyNFT(name, price, quantity, royalties, token, logoURL, nftImageURL) {
    let args = fcl.args([
      fcl.arg(name, fcl.t.String),
      fcl.arg(price, fcl.t.UFix64),
      fcl.arg(quantity, fcl.t.UInt32),
      fcl.arg(royalties, fcl.t.UInt8),
      fcl.arg(token, fcl.t.String),
      fcl.arg(logoURL, fcl.t.String),
      fcl.arg(nftImageURL, fcl.t.String)
    ])
    return this.sendTransaction(CREATE_COLLECTION_TX, args)
  }

}

export default BigDaddyMinterTransactions
