import React from 'react';
import {
    CCard,
    CCardHeader,
    CCardBody,
} from '@coreui/react';

function BigDaddyStartPage() {
    return (
        <>
            <div className="bdn-centered-title">
                <p style={{ color: 'white', paddingBottom: "20px"}}>
                Start fundraising!
                </p>

                <iframe width="75%" height="542" src="https://www.youtube.com/embed/32cbSA4VpFw"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
            </div>
        </>

    );
}

export default BigDaddyStartPage;

