import React, { useState, useRef, createContext, useContext } from 'react';
import { CToast, CToastBody, CToaster, CToastHeader, CModal, CModalHeader, CModalBody } from "@coreui/react";

const BigDaddyToastAndErrorModalContext = createContext();

export const useBigDaddyToastAndErrorModal = () => {
    return useContext(BigDaddyToastAndErrorModalContext);
};

const BigDaddyToastAndErrorModalProvider = ({ children }) => {
    const [toast, addToast] = useState(null);
    const [isModalVisible, setModalVisibility] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const toaster = useRef();
    const pushToast = () => {
        addToast((
            <CToast>
                <CToastHeader>
                    <div className="fw-bold me-auto">Success 🎉</div>
                    <small>Just now</small>
                </CToastHeader>
                <CToastBody>
                    Great news! Your transaction on the blockchain was successful 🚀!
                </CToastBody>
            </CToast>
        ));

        setTimeout(() => {
            addToast(null);
        }, 3000);
    };

    const showBigDaddyErrorModal = (errorMessage) => {
        setModalMessage(errorMessage);
        setModalVisibility(true);
        console.log(isModalVisible);
    };


    return (
        <BigDaddyToastAndErrorModalContext.Provider value={{ pushToast, showBigDaddyErrorModal }}>
            {children}

            <CToaster ref={toaster} push={toast} placement="top-end" />

            <CModal visible={isModalVisible} alignment="center" size="xl" onClose={() => setModalVisibility(false)} style={{overflowY: "auto", wordWrap: "break-word"}}>
                <CModalHeader closeButton>Error</CModalHeader>
                <CModalBody>{modalMessage}</CModalBody>
            </CModal>
        </BigDaddyToastAndErrorModalContext.Provider>
    );
};

export default BigDaddyToastAndErrorModalProvider;
