import React, { useState, useEffect } from 'react';
import {
    CFormInput,
    CContainer,
    CFormLabel,
    CRow,
    CCol,
    CFormTextarea,
    CFormCheck,
    CCardHeader,
    CCard
} from '@coreui/react';
import { useBigDaddyDeveloperContext } from '../../Providers/BigDaddyDeveloperContext';

function BigDaddyDeveloperPage() {
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0.0);
  const [projectId, setProjectId] = useState("");
  const [description, setDescription] = useState("");
  const [miniatureURL, setMiniatureURL] = useState("");
  const [picture1Url, setPicture1Url] = useState("");
  const [picture2Url, setPicture2Url] = useState("");
  const [picture3Url, setPicture3Url] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [creatorwebsiteUrl, setCreatorwebsiteUrl] = useState("");
  const [demowebsiteUrl, setDemowebsiteUrl] = useState("");
  const [selectedToken, setSelectedToken] = useState('FLOW');

  const {handleCreateNewBigDaddyNFTTemplate, nftTemplate, handleUpdateBigDaddyNFTTemplate, getBigDaddyDeveloperTemplate } = useBigDaddyDeveloperContext();

  useEffect(() => {
      const fetchTemplates = async () => {
          let devTemplate = await getBigDaddyDeveloperTemplate();
          if (devTemplate != null) {
              setName(devTemplate.name);
              setPrice(devTemplate.price);
              setProjectId(devTemplate.projectId);
              setDescription(devTemplate.description);
              setMiniatureURL(devTemplate.miniatureURL);
              setPicture1Url(devTemplate.picture1Url);
              setPicture2Url(devTemplate.picture2Url);
              setPicture3Url(devTemplate.picture3Url);
              setVideoUrl(devTemplate.videoUrl);
              setCreatorwebsiteUrl(devTemplate.ownerwebsiteUrl);
              setDemowebsiteUrl(devTemplate.demowebsiteUrl);
              setSelectedToken(devTemplate.token)
          }
      };

      fetchTemplates().then();
  }, []);

  const createTemplate = () => {
    let finalPrice = price;
    if(Number.isInteger(Number(price))) {
      finalPrice = parseFloat(price).toFixed(1);
    }
    handleCreateNewBigDaddyNFTTemplate(name, finalPrice, projectId, description, miniatureURL, picture1Url, picture2Url, picture3Url, videoUrl, creatorwebsiteUrl, demowebsiteUrl, selectedToken) ;
  };

  const updateTemplate = () => {
    let finalPrice = price;
    if(Number.isInteger(Number(price))) {
      finalPrice = parseFloat(price).toFixed(1);
    }
    handleUpdateBigDaddyNFTTemplate(nftTemplate.templateID, name, finalPrice, projectId, description, miniatureURL, picture1Url, picture2Url, picture3Url, videoUrl, creatorwebsiteUrl, demowebsiteUrl, selectedToken) ;
  };


  return (
      <>
          <CCard className="m-5" style={{background: "rgba(255, 255, 255, 0.6)"}}>
              <CCardHeader style={{ textAlign: 'center', fontWeight: 'bold',  fontSize: '24px'}}>My Website Template</CCardHeader>
     <CContainer>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
          <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'purple'}}>Name</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={name} onChange={e => setName(e.target.value)} />
            </CCol>
            </CCol>
            <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'purple'}}>Price</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="number" step="0.01" value={price} onChange={e => setPrice(e.target.value)} />
            </CCol>
            </CCol>
          </CRow>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
          <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'purple'}}>ProjectId</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={projectId} onChange={e => setProjectId(e.target.value)} disabled={nftTemplate !== null}/>
            </CCol>
            </CCol>
            <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'purple'}}>Description</CFormLabel>
            <CCol sm={10} className="mx-3">
              <CFormTextarea value={description} onChange={e => setDescription(e.target.value)} />
            </CCol>
            </CCol>
          </CRow>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
          <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'purple'}}>Miniature URL</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={miniatureURL} onChange={e => setMiniatureURL(e.target.value)} />
            </CCol>
            </CCol>
            <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'purple'}}>First Picture URL</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={picture1Url} onChange={e => setPicture1Url(e.target.value)} />
            </CCol>
            </CCol>
          </CRow>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
          <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'purple'}}>Second Picture URL</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={picture2Url} onChange={e => setPicture2Url(e.target.value)} />
            </CCol>
            </CCol>
            <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'purple'}}>Third Picture URL</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={picture3Url} onChange={e => setPicture3Url(e.target.value)} />
            </CCol>
            </CCol>
          </CRow>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
          <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'purple'}}>Video URL</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={videoUrl} onChange={e => setVideoUrl(e.target.value)} />
            </CCol>
            </CCol>
            <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'purple'}}>Creator Website URL</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={creatorwebsiteUrl} onChange={e => setCreatorwebsiteUrl(e.target.value)}/>
            </CCol>
            </CCol>
          </CRow>
          <CRow className="mb-3" xs={{ gutterX: 5 }}>
          <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{color: 'purple'}}>Demo Website URL</CFormLabel>
            <CCol sm={10} className="mx-3">
                <CFormInput type="text" value={demowebsiteUrl} onChange={e => setDemowebsiteUrl(e.target.value)} />
            </CCol>
            </CCol>
            <CCol>
            <CFormLabel className="col-sm-8 col-form-label" style={{ color: 'purple' }}>Choose the token you want to use to get paid:</CFormLabel>
              <CCol sm={10} className="mx-3">
              <CFormCheck type="radio" name="tokenRadio" id="FLOW" label="FLOW" 
                  value="FLOW"
                  onChange={(e) => setSelectedToken(e.target.value)}
                  checked={selectedToken === 'FLOW'}
              />
              <CFormCheck type="radio" name="tokenRadio" id="USDC" label="USDC"
                  value="USDC"
                  onChange={(e) => setSelectedToken(e.target.value)}
                  checked={selectedToken === 'USDC'}
              />
              </CCol>
            </CCol>
            
          </CRow>
          <CRow className="mb-3" xs={{ gutterX: 5 }} >
          <CCol><div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
          {nftTemplate === null ? (<button onClick={createTemplate} className="bigdaddy-button" >Create my Website Template</button>) : (<button onClick={updateTemplate} className="bigdaddy-button" style={{ margin: '40px 0px 0px 40px'}}>Update my Website Template</button>)}
          </div></CCol>
          </CRow>
        </CContainer>
          </CCard>


    </>

  );
}

export default BigDaddyDeveloperPage;
