// BigDaddyComponent.js

import React from 'react';
import BigDaddyDeveloperPage from './BigDaddyDeveloperPage';
import BigDaddyActivateCollectionPage from '../../Components/bdnhub/BigDaddyActivateCollectionPage';
import BigDaddyLoginPage from '../../Components/bdnhub/BigDaddyLoginPage';
import {useBDNHubContext} from "../../Providers/bdnHubContext";


const BigDaddyDeveloperComponent = () => {
  const { isLoggedIn,
    isCollectionEnabled} = useBDNHubContext();

  return (
    <div>
        {isLoggedIn ? (!isCollectionEnabled ? ( <BigDaddyActivateCollectionPage/> ) : ( <BigDaddyDeveloperPage /> )) : ( <BigDaddyLoginPage/> )}
    </div>
  );
};


export default BigDaddyDeveloperComponent;