// BigDaddyMarketplaceComponent.js

import BigDaddyMarketplaceNFTBuyerPage from './BigDaddyMarketplaceNFTBuyerPage.jsx';
import BigDaddyMarketplaceActivateCollectionPage from '../bdnhub/BigDaddyActivateCollectionPage.jsx';
import BigDaddyLoginPage from '../bdnhub/BigDaddyLoginPage.jsx';
import {useBDNHubContext} from "../../Providers/bdnHubContext";


const BigDaddyMarketplaceComponent = () => {
  const {  isLoggedIn,
    isCollectionEnabled } = useBDNHubContext();

  return (
      <div>
      {isLoggedIn ? (!isCollectionEnabled ? ( <BigDaddyMarketplaceActivateCollectionPage/> ) : ( <BigDaddyMarketplaceNFTBuyerPage/> )) : ( <BigDaddyLoginPage/> )}
    </div>
  );
};


export default BigDaddyMarketplaceComponent;