
import { useState, createContext, useContext } from 'react';
import BdnHubScripts from '../Flow/bdnHubScripts';
import BdnHubTransactions from "../Flow/bdnHubTransactions";
import { useBigDaddyToastAndErrorModal } from './BigDaddyToastAndErrorModalProvider';

const bdnHubContext = createContext();

export function useBDNHubContext() {
    return useContext(bdnHubContext);
}

export function BdnHubProvider({ children }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isCollectionEnabled, setIsCollectionEnabled] = useState(false);
    const [user, setUser] = useState(null);
    const [isBigDaddyLoading, setIsBigDaddyLoading] = useState(false);
    const [flowBalance, setFlowBalance] = useState(0);
    const [usdcBalance, setUsdcBalance] = useState(0);

    const bdnHubScripts = new BdnHubScripts();
    const bdnHubTransactions = new BdnHubTransactions();
    const {pushToast, showBigDaddyErrorModal} = useBigDaddyToastAndErrorModal();

    const validateLoggedIn = (myuser) => {

        if (user == null) {
            setUser(myuser);
            setIsLoggedIn(true);
        }
    };

    const disconnect = () => {
        if (user !== null) {
            setUser(null);
            setIsLoggedIn(false);
        }
    };
    const hasBigDaddyCollections = async () => {
        setIsBigDaddyLoading(true);
        try {
            const collectionExists = await bdnHubScripts.hasBigDaddyCollections(user.addr);
            setIsCollectionEnabled(collectionExists);
        } catch (error) {
            showBigDaddyErrorModal(error.message);
        } finally {
            setIsBigDaddyLoading(false);
        }
    };

    const handleActivateBigDaddyCollections = async () => {
        setIsBigDaddyLoading(true);
        try {
            await bdnHubTransactions.enableBigDaddyCollections();
            setIsCollectionEnabled(hasBigDaddyCollections);
        } catch (error) {
            showBigDaddyErrorModal(error.message);
        } finally {
            setIsBigDaddyLoading(false);
            pushToast();
        }
    };
    const getBalance = async () => {
        setIsBigDaddyLoading(true);
        try {
            const usdcBalance = await bdnHubScripts.getUSDCBalance(user.addr);
            const flowBalance = await bdnHubScripts.getFLOWBalance(user.addr);
            setUsdcBalance(usdcBalance);
            setFlowBalance(flowBalance);
        } catch (error) {
            showBigDaddyErrorModal(error.message);
        } finally {
            setIsBigDaddyLoading(false);
        }
    };



    return (
        <bdnHubContext.Provider value={{ isBigDaddyLoading,
            isLoggedIn,
            isCollectionEnabled,
            user,
            flowBalance,
            usdcBalance,
            validateLoggedIn,
            disconnect,
            handleActivateBigDaddyCollections,
            getBalance,
            hasBigDaddyCollections,
            setIsBigDaddyLoading}}>
            {children}
        </bdnHubContext.Provider>
    );
}