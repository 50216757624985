import React from 'react';
import {
    CCardBody,
    CCard,
    CRow,
    CCol, CCardHeader, CCardFooter, CImage, CContainer,
} from '@coreui/react';

function BigDaddyHowItWorks() {
    const cardstyle = {
        background: "black",
        borderRadius: "15px", // Bordures arrondies
        boxShadow: "0px 4px 6px #00000029",
        maxWidth: "520px", width: '100%'// Ombre
    }

    return (
        <CContainer fluid className="p-0">
            <div className="bdn-centered-title">
                <p style={{ color: 'white'}}> Discover bdnHub</p>
                <p style={{
                    background: 'linear-gradient(to right, #00c9ff, #00bcd4, #00e19d, #07934e)',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    fontWeight: 'bold',
                    fontSize: '2rem',
                    paddingBottom: '20px'}}>bdnHub is the one-stop solution for owning and monetizing your Web3 fundraising campaigns.</p>
                <CContainer fluid className="p-0 d-flex space-between">

                <CRow>
                    <CCol md="3" className="text-center my-5">
                        <CCard style={cardstyle} className="h-100 mb-3 text-center animated-card ">
                            <CCardHeader>
                                <h2 style={{ color: 'white'}}>Create Your NFT Collection</h2>
                            </CCardHeader>
                            <CCardBody>
                        <CImage fluid src={"/step1-wu.jpg"} />
                            </CCardBody>
                            <CCardFooter>
                        <p style={{
                            background: 'linear-gradient(to right, #00c9ff, #00bcd4, #00e19d, #07934e)',
                            WebkitBackgroundClip: 'text',
                            color: 'transparent', fontSize: '1rem'}}>New to NFTs? No worries! With bdnHub, creating your own NFT collection is a walk in the park. Simply go to "My Collections" to start crafting your unique digital assets. Own your story.</p>
                                </CCardFooter>
                            </CCard>
                    </CCol>

                    <CCol md="3" className="text-center my-5">
                        <CCard style={cardstyle} className="h-100 mb-3 text-center animated-card ">
                            <CCardHeader>
                                <h2 style={{ color: 'white'}}>Buy a WebSite Template</h2>
                            </CCardHeader>
                            <CCardBody>
                                <CImage fluid src={"/step2-wu.jpg"} /></CCardBody>
                            <CCardFooter>
                                <p style={{
                                    background: 'linear-gradient(to right, #00c9ff, #00bcd4, #00e19d, #07934e)',
                                    WebkitBackgroundClip: 'text',
                                    color: 'transparent', fontSize: '1rem'}}>Explore our Marketplace to find the perfect website template that aligns with your vision. Select, purchase, and you're halfway to launching your digital empire.</p>
                            </CCardFooter>
                        </CCard>
                            </CCol>

                    <CCol md="3" className="text-center my-5">
                        <CCard style={cardstyle} className="h-100 mb-3 text-center animated-card ">
                            <CCardHeader>
                                <h2 style={{ color: 'white'}}>Deploy Your Custom Website</h2>
                            </CCardHeader>
                            <CCardBody>
                                <CImage fluid src={"/step3-wu.jpg"} /></CCardBody>
                            <CCardFooter>
                                <p style={{
                                    background: 'linear-gradient(to right, #00c9ff, #00bcd4, #00e19d, #07934e)',
                                    WebkitBackgroundClip: 'text',
                                    color: 'transparent', fontSize: '1rem'}}>Got your template? Awesome! Now deploy it as a fully functional website. Streamline your Web3 fundraising campaigns, engage with supporters, and offer unique experiences all from your personalized platform.</p>
                            </CCardFooter>
                        </CCard>
                            </CCol>

                    <CCol md="3" className="text-center my-5">
                        <CCard style={cardstyle} className="h-100 mb-3 text-center animated-card ">
                            <CCardHeader>
                                <h2 style={{ color: 'white'}}>Master Your Fundraising Campaign</h2>
                            </CCardHeader>
                            <CCardBody>
                                <CImage fluid src={"/step4-wu.jpg"} /></CCardBody>
                            <CCardFooter>
                                <p style={{
                                    background: 'linear-gradient(to right, #00c9ff, #00bcd4, #00e19d, #07934e)',
                                    WebkitBackgroundClip: 'text',
                                    color: 'transparent', fontSize: '1rem'}}>With bdnHub, you are in the driver's seat of your Web3 fundraising campaign. All funds raised go directly to your personal wallet—no commissions, no financial intermediaries. Experience true ownership and financial freedom.</p>
                            </CCardFooter>
                        </CCard>
                            </CCol>
                </CRow>
                </CContainer>
            </div>
        </CContainer>
    );
}

export default BigDaddyHowItWorks;
