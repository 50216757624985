import React, {useEffect, useRef, useState} from 'react';
import {CCard, CCardBody, CCardFooter, CCardHeader, CContainer, CImage} from "@coreui/react";
import BigDaddyHowItWorksPage from "./BigDaddyHowItWorksPage";
import BigDaddyWhyUsPage from "./BigDaddyWhyUsPage";
import BigDaddyStartPage from "./BigDaddyStartPage";
import BigDaddyContactPage from "./BigDaddyContactPage";
import ReactCardFlip from 'react-card-flip';

function BigDaddyHomePage() {
    const [isFlippedCard1, setIsFlippedCard1] = useState(false);
    const [isFlippedCard2, setIsFlippedCard2] = useState(false);
    const [isFlippedCard3, setIsFlippedCard3] = useState(false);
    const [isFlippedCard4, setIsFlippedCard4] = useState(false);
    const [isFlippedCard5, setIsFlippedCard5] = useState(false);
    const imageRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        if (imageRef.current) {
            const { width, height } = imageRef.current.getBoundingClientRect();
            setDimensions({ width, height });
        }
    }, [imageRef]);

    const cardstyle = {
        background: "black",
        borderRadius: "15px", // Bordures arrondies
        boxShadow: "0px 4px 6px #00000029",
        maxWidth: "520px", width: '100%'// Ombre
    }


    const flipCard1 = () => setIsFlippedCard1(!isFlippedCard1);

    return (
        <>
            <CContainer fluid className="p-0">

                <div className="bdn-centered-title">
                        <p style={{ color: 'white'}}> Fundraise on Your Terms</p>
                        <p style={{
                            background: 'linear-gradient(to right, #00c9ff, #00bcd4, #00e19d, #07934e)',
                            WebkitBackgroundClip: 'text',
                            color: 'transparent',
                            fontWeight: 'bold',
                            paddingBottom: '20px'}}> Your Campaign , Your Wallet , Your Rules</p>
                    <CContainer fluid className="p-0 d-flex justify-content-center">

                            <CCard style={cardstyle} className="mb-3 text-center animated-card ">
                                <CCardHeader>
                                    <h2 style={{ color: 'white'}}>Welcome to bdnhub</h2>
                                </CCardHeader>
                                <CCardBody>
                                    <CImage fluid ref={imageRef} src={"/bdn-welcome.jpg"}/>
                                </CCardBody>
                                <CCardFooter>
                                    <>
                                        <p style={{
                                            background: 'linear-gradient(to right, #00c9ff, #00bcd4, #00e19d, #07934e)',
                                            WebkitBackgroundClip: 'text',
                                            color: 'transparent', fontSize: '1rem'}}>
                                            Are you tired of traditional fundraising platforms that act as a middleman between you and your donors?
                                        </p>
                                        <p style={{
                                            background: 'linear-gradient(to right, #00c9ff, #00bcd4, #00e19d, #07934e)',
                                            WebkitBackgroundClip: 'text',
                                            color: 'transparent', fontSize: '1rem'}}>
                                            Here at bdnhub, we do things differently. <strong>We're not an intermediary; we're a facilitator.</strong>
                                        </p>
                                        <p style={{
                                            background: 'linear-gradient(to right, #00c9ff, #00bcd4, #00e19d, #07934e)',
                                            WebkitBackgroundClip: 'text',
                                            color: 'transparent', fontSize: '1rem'}}>
                                            Our disruptive approach allows you to host your own personalized fundraising site. You have the freedom to run your campaign your way, with your own rules.
                                        </p>
                                        <p style={{
                                            background: 'linear-gradient(to right, #00c9ff, #00bcd4, #00e19d, #07934e)',
                                            WebkitBackgroundClip: 'text',
                                            color: 'transparent', fontSize: '1rem'}}>
                                            <strong>Your Campaign, Your Wallet, Your Rules.</strong>
                                        </p>
                                    </>
                                </CCardFooter>
                            </CCard>
                    </CContainer>
                </div>
            </CContainer>
            <BigDaddyHowItWorksPage />
            <BigDaddyWhyUsPage />
            <BigDaddyStartPage />
            <BigDaddyContactPage />
        </>
    );
}

export default BigDaddyHomePage;
