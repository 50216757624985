// BigDaddyComponent.js

import React from 'react';
import BigDaddyMinterPage from './BigDaddyMinterPage';
import BigDaddyActivateCollectionPage from '../../Components/bdnhub/BigDaddyActivateCollectionPage';
import BigDaddyLoginPage from '../../Components/bdnhub/BigDaddyLoginPage';
import {useBDNHubContext} from "../../Providers/bdnHubContext";

const BigDaddyMinterComponent = () => {
  const {   isLoggedIn,
    isCollectionEnabled} = useBDNHubContext();

  return (
      <div>
        {isLoggedIn ? (!isCollectionEnabled ? ( <BigDaddyActivateCollectionPage /> ) : ( <BigDaddyMinterPage /> )) : (<BigDaddyLoginPage/> )}
      </div>
  );
};

export default BigDaddyMinterComponent;