export const BUY_NFT_TX = `import BigDaddyMarketplaceContract from 0xBdn
import FungibleToken from 0xFungibleToken
import FlowToken from 0xFlow
import FiatToken from 0xFiat

transaction(templateId: UInt32) {
  let receiverReference: &BigDaddyMarketplaceContract.Collection{BigDaddyMarketplaceContract.Receiver}
  let sentVault: @FungibleToken.Vault
  let template : BigDaddyMarketplaceContract.Template

  prepare(signer: AuthAccount) {
    self.template = BigDaddyMarketplaceContract.getTemplate(templateId: templateId) ?? panic("No way to find template to buy")

    self.receiverReference = signer.borrow<&BigDaddyMarketplaceContract.Collection{BigDaddyMarketplaceContract.Receiver}>(from: BigDaddyMarketplaceContract.NFTMarketplaceCollectionStoragePath) 
          ?? panic("Cannot borrow")

    if (self.template.token == "USDC"){
      // Get a reference to the signer's stored vault
      let vaultRef = signer.borrow<&FiatToken.Vault>(from: FiatToken.VaultStoragePath)
          ?? panic("Could not borrow reference to the owner's Vault!")
      self.sentVault <- vaultRef.withdraw(amount: self.template.price)
    }
    else {
      // Get a reference to the signer's stored vault
      let vaultRef = signer.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
          ?? panic("Could not borrow reference to the owner's Vault!")
      self.sentVault <- vaultRef.withdraw(amount: self.template.price)
    }
  }

  execute {
    let nft <- BigDaddyMarketplaceContract.buyBigDaddyMarketplaceNFT(templateID: templateId, sentVault: <- self.sentVault) ?? panic("buyBigDaddyMarketplaceNFT returned a null value")
    self.receiverReference.deposit(token: <- nft!)
  }
}`;